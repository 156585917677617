
import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

interface OpeningHoursDataObject {
  day: string;
  open: string;
  close: string;
}

const ConceptsSupportedOrderTypesProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
  },
  computed: {},
  mixins: [translations],
})
export default class ConceptsSupportedOrderTypes extends ConceptsSupportedOrderTypesProps {
  translate!: Function;
}
